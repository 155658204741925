import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import {
  CardType,
  initialState as cardTypesInitialState,
} from "../cardTypes/cardTypesSlice";
import { refreshShopCard } from "../refreshes/refreshesSlice";

export interface Shop {
  cardTypesForSale: CardType[];
  counter: 1;
}

let savedState;
if (localStorage.getItem("animal-game-state") !== null) {
  savedState = JSON.parse(localStorage.getItem("animal-game-state") as string)
    .shop as Shop;
}

const initialState =
  savedState && savedState.counter === 1
    ? savedState
    : {
        cardTypesForSale: _.shuffle(
          cardTypesInitialState.filter(
            (cardType) => cardType.variant !== "BASE"
          )
        ).slice(0, 3),
        counter: 1,
      };

if (localStorage.getItem("animal-game-state") !== null) {
  const state = JSON.parse(localStorage.getItem("animal-game-state") as string);
  state.shop = initialState;
  localStorage.setItem("animal-game-state", JSON.stringify(state));
}

export const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(refreshShopCard, (state, action) => {
      const newRandomCardType = _.shuffle(
        cardTypesInitialState.filter(
          (cardType) =>
            !state.cardTypesForSale.find(
              (ctfs) =>
                ctfs.species === cardType.species &&
                ctfs.variant === cardType.variant
            ) &&
            !action.payload.ownedCardTypes.find(
              (oct) =>
                oct.species === cardType.species &&
                oct.variant === cardType.variant
            )
        )
      )[0];

      state.cardTypesForSale = [
        ...state.cardTypesForSale.slice(0, action.payload.i),
        newRandomCardType,
        ...state.cardTypesForSale.slice(action.payload.i + 1),
      ];
    });
  },
});

export const shopReducer = shopSlice.reducer;
