import { CardTypeSpecies } from "../features/cardTypes/cardTypesSlice";

export function getStripeColorFromCardTypeSpecies(string: CardTypeSpecies) {
  switch (string) {
    case "GECKO":
      return "#fd04";
    case "WOLF":
      return "#04d2";
    case "RED_PANDA":
      return "#d202";
    case "OCTOPUS":
      return "#6ce4";
    default:
      return "red";
  }
}
