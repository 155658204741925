import { CardType } from "../features/cardTypes/cardTypesSlice";
import { MissionType } from "../features/missions/missionsSlice";

export function isCardTypeEligibleForMission({
  cardType,
  missionType,
}: {
  cardType: CardType;
  missionType: MissionType;
}) {
  if (cardType.variant === "ASTRONAUT") {
    return true;
  } else {
    return false;
  }
}
