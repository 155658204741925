import { CardTypeSpecies, CardTypeVariant } from "./cardTypesSlice";

export function CardHead({
  species,
  variant,
}: {
  species: CardTypeSpecies;
  variant: CardTypeVariant;
}) {
  return (
    <img
      src={`${species.toLowerCase()}__${variant.toLowerCase()}.png`}
      style={{
        height: "96px",
        width: "96px",
        borderRadius: "48px",
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
      }}
    />
  );
}
