import { createAction, createSlice } from "@reduxjs/toolkit";

export const claimReward = createAction<{
  rewardId: number;
}>("claimReward");

export const createAndClaimReward = createAction<{
  rewardId: number;
}>("createAndClaimReward");

export interface Reward {
  rewardId: number;
  hasBeenClaimed: boolean;
}

let savedState;
if (localStorage.getItem("animal-game-state") !== null) {
  savedState = JSON.parse(localStorage.getItem("animal-game-state") as string)
    .rewards as Reward[];
}

const initialState: Reward[] = savedState
  ? savedState
  : [
      {
        rewardId: 1,
        hasBeenClaimed: false,
      },
    ];

export const rewardsSlice = createSlice({
  name: "rewardsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(claimReward, (state, action) => {
        const reward = state.find(
          (reward) => reward.rewardId === action.payload.rewardId
        );

        if (!reward) {
          throw new Error("Reward not found");
        }

        reward.hasBeenClaimed = true;
      })
      .addCase(createAndClaimReward, (state, action) => {
        const reward = state.find(
          (reward) => reward.rewardId === action.payload.rewardId
        );

        if (reward) {
          throw new Error("Reward already exists ");
        }

        if (action.payload.rewardId === 2) {
          state.push({
            rewardId: action.payload.rewardId,
            hasBeenClaimed: true,
          });
        }

        if (action.payload.rewardId === 3) {
          state.push({
            rewardId: action.payload.rewardId,
            hasBeenClaimed: true,
          });
        }
      });
  },
});

export const rewardsReducer = rewardsSlice.reducer;
