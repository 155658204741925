import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CardType } from "../cardTypes/cardTypesSlice";

export function getMissionRequirementsByMissionType({
  missionId,
}: {
  missionId: MissionType;
}) {
  if (missionId === "BASE") {
    return [{ species: "ANY", variant: "ANY", count: 1 }];
  }

  if (missionId === "MOON_MISSION") {
    return [{ species: "ANY", variant: "ASTRONAUT", count: 1 }];
  }
}

export function getMissionDurationInSecondsByType(missionType: MissionType) {
  if (missionType === "BASE") {
    return 60 * 60 * 24;
  }

  if (missionType === "MOON_MISSION") {
    return 60 * 60 * 24 * 2;
  }

  throw Error("Invalid mission type: " + missionType);
}

export type MissionType = "BASE" | "MOON_MISSION";

export type MissionToken = {
  missionType: MissionType;
  assignedCards: CardType[];
  startTimestamp?: number;
  hasRewardBeenClaimed: boolean;
};

let savedState;
if (localStorage.getItem("animal-game-state") !== null) {
  savedState = JSON.parse(localStorage.getItem("animal-game-state") as string)
    .missions as MissionToken[];
}

export const initialState: MissionToken[] =
  savedState && typeof savedState[0] !== "string" // to replace old ["BASE", "MISSION"] state
    ? savedState
    : [
        {
          missionType: "MOON_MISSION",
          assignedCards: [],
          startTimestamp: undefined,
          hasRewardBeenClaimed: false,
        },
      ];

export const cardsSlice = createSlice({
  name: "missions",
  initialState,
  reducers: {
    assignCardToMission: (
      state,
      action: PayloadAction<{ cardType: CardType; missionType: MissionType }>
    ) => {
      const { cardType, missionType } = action.payload;
      const mission = state.find(
        (mission) => mission.missionType === missionType
      );
      if (!mission) {
        throw new Error("Mission not found");
      }

      if (
        mission.assignedCards.find(
          (ct) =>
            ct.species === cardType.species && ct.variant === cardType.variant
        )
      ) {
        const i = mission.assignedCards.findIndex(
          (ct) =>
            ct.species === cardType.species && ct.variant === cardType.variant
        );
        mission.assignedCards = [
          ...mission.assignedCards.slice(0, i - 1),
          ...mission.assignedCards.slice(i + 1),
        ];
      } else {
        mission.assignedCards.push(cardType);
      }
    },
    startMission: (
      state,
      action: PayloadAction<{ missionType: MissionType }>
    ) => {
      const mission = state.find(
        (mission) => mission.missionType === action.payload.missionType
      );

      if (!mission) {
        throw new Error("Mission not found");
      }

      mission.startTimestamp = Date.now();
    },
    claimRewards: (
      state,
      action: PayloadAction<{ missionType: MissionType }>
    ) => {
      const mission = state.find(
        (mission) => mission.missionType === action.payload.missionType
      );

      if (!mission) {
        throw new Error("Mission not found");
      }

      mission.hasRewardBeenClaimed = true;
    },
  },
});

export const { assignCardToMission, startMission, claimRewards } =
  cardsSlice.actions;

export const missionsReducer = cardsSlice.reducer;
