export function getMissionTitleFromType(type: string) {
  switch (type) {
    case "BASE":
      return "Base Mission";
    case "MOON_MISSION":
      return "Moon Mission 🌑🌒🌓🌔🌕🌖🌗🌘🌑";
    default:
      throw Error("Invalid mission type: " + type);
  }
}
