import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { cardTypesReducer } from "../features/cardTypes/cardTypesSlice";
import { itemStacksReducer } from "../features/itemStacks/itemStacksSlice";
import { missionsReducer } from "../features/missions/missionsSlice";
import { playerReducer } from "../features/player/playerSlice";
import { refreshesReducer } from "../features/refreshes/refreshesSlice";
import { rewardsReducer } from "../features/rewards/rewardsSlice";
import { shopReducer } from "../features/shop/shopSlice";
import { shopInventoryManagerReducer } from "../features/shopInventoryManager/shopInventoryManagerSlice";

export const store = configureStore({
  reducer: {
    cardTypes: cardTypesReducer,
    itemStacks: itemStacksReducer,
    missions: missionsReducer,
    player: playerReducer,
    refreshes: refreshesReducer,
    rewards: rewardsReducer,
    shop: shopReducer,
    shopInventoryManager: shopInventoryManagerReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat((store) => (next) => (action) => {
      next(action);
      localStorage.setItem(
        "animal-game-state",
        JSON.stringify(store.getState())
      );
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
