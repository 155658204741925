import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CardType, CardTypeSpecies } from "../cardTypes/cardTypesSlice";
import { claimRewards, startMission } from "../missions/missionsSlice";
import { claimReward, createAndClaimReward } from "../rewards/rewardsSlice";
import { purchaseCard } from "../shopInventoryManager/shopInventoryManagerSlice";

export interface Player {
  ownedCardTypes: CardType[];
  numGoldCoins: number;
  hasSelectedStartingCard: boolean;
  counter: 1;
}

let savedState;
if (localStorage.getItem("animal-game-state") !== null) {
  savedState = JSON.parse(localStorage.getItem("animal-game-state") as string)
    .player as Player;
}

const initialState =
  savedState && savedState.counter === 1
    ? savedState
    : {
        ownedCardTypes: [
          { species: "GECKO", variant: "BASE" },
          { species: "WOLF", variant: "BASE" },
          { species: "RED_PANDA", variant: "BASE" },
        ] as CardType[],
        numGoldCoins: 5000,
        hasSelectedStartingCard: false,
        counter: 1,
      };

export const playerSlice = createSlice({
  name: "player",
  initialState,
  reducers: {
    selectStartingCard: (state, action: PayloadAction<CardTypeSpecies>) => {
      const startingSpecies = action.payload;
      state.ownedCardTypes = [{ species: startingSpecies, variant: "BASE" }];
      state.hasSelectedStartingCard = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(purchaseCard, (state, action) => {
        state.ownedCardTypes.push(action.payload.cardType);
        state.numGoldCoins -= action.payload.price;
      })
      .addCase(claimReward, (state, action) => {
        if (action.payload.rewardId === 1) state.numGoldCoins += 5000;
      })
      .addCase(createAndClaimReward, (state, action) => {
        if (action.payload.rewardId === 2) state.numGoldCoins += 5000;
        if (action.payload.rewardId === 3) state.numGoldCoins += 5000;
      })
      .addCase(startMission, (state, action) => {
        if (action.payload.missionType === "MOON_MISSION") {
          state.numGoldCoins += 3000;
        }
      })
      .addCase(claimRewards, (state, action) => {
        if (action.payload.missionType === "MOON_MISSION") {
          state.numGoldCoins += 3000;
        }
      });
  },
});

export const { selectStartingCard } = playerSlice.actions;

export const playerReducer = playerSlice.reducer;
