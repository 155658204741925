import { CardType, CardTypeRarity } from "../features/cardTypes/cardTypesSlice";

export function getRarityFromCardType(cardType: CardType): CardTypeRarity {
  switch (`${cardType.species}__${cardType.variant}`) {
    case "GECKO__BASE":
      return "COMMON";
    case "GECKO__TUXEDO":
      return "UNCOMMON";
    case "GECKO__ASTRONAUT":
      return "UNCOMMON";
    case "GECKO__OIL_PAINTING":
      return "RARE";
    case "GECKO__BLACK_AND_WHITE":
      return "RARE";
    case "GECKO__PSYCHEDELIC":
      return "RARE";
    case "GECKO__WIZARD":
      return "RARE";
    case "GECKO__STAINED_GLASS":
      return "EPIC";
    case "WOLF__BASE":
      return "COMMON";
    case "WOLF__TUXEDO":
      return "UNCOMMON";
    case "WOLF__ASTRONAUT":
      return "UNCOMMON";
    case "WOLF__WIZARD":
      return "RARE";
    case "WOLF__WARLOCK":
      return "RARE";
    case "WOLF__OIL_PAINTING":
      return "RARE";
    case "WOLF__VAN_GOGH":
      return "RARE";
    case "WOLF__STAINED_GLASS":
      return "EPIC";
    case "RED_PANDA__BASE":
      return "COMMON";
    case "RED_PANDA__TUXEDO":
      return "UNCOMMON";
    case "RED_PANDA__ASTRONAUT":
      return "UNCOMMON";
    case "RED_PANDA__OIL_PAINTING":
      return "RARE";
    case "RED_PANDA__ULTRA_TURQUOISE":
      return "LEGENDARY";
    case "OCTOPUS__BASE":
      return "COMMON";
    case "OCTOPUS__ASTRONAUT":
      return "UNCOMMON";
    case "OCTOPUS__OIL_PAINTING":
      return "RARE";
    case "OCTOPUS__BLACK_AND_WHITE":
      return "RARE";
    case "OCTOPUS__STAINED_GLASS":
      return "EPIC";
    default:
      throw Error("Invalid card type");
  }
}
