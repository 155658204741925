import { Box, Center } from "@chakra-ui/react";
import { useAppSelector } from "../../app/hooks";
import { Mission } from "./Mission";

export function Missions() {
  const missions = useAppSelector((state) =>
    state.missions.filter((mission) => !mission.hasRewardBeenClaimed)
  );

  return (
    <Box mt="24px">
      {missions.length > 0 ? (
        missions.map((mission) => (
          <Center mb="16px">
            <Mission mission={mission} />
          </Center>
        ))
      ) : (
        <Center fontSize="24px">More missions coming soon :)</Center>
      )}
    </Box>
  );
}
