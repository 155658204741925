import {
  Box,
  Button,
  Card as ChakraCard,
  CardBody,
  CardFooter,
  Center,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Wrap,
} from "@chakra-ui/react";
import Confetti from "js-confetti";
import _ from "lodash";
import { useEffect, useState } from "react";
import "./App.css";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { Card } from "./features/cardTypes/Card";
import { CardTypeSpecies } from "./features/cardTypes/cardTypesSlice";
import { ItemStack } from "./features/itemStacks/ItemStack";
import { Missions } from "./features/missions/Missions";
import { selectStartingCard } from "./features/player/playerSlice";
import { refreshShopCard } from "./features/refreshes/refreshesSlice";
import {
  claimReward,
  createAndClaimReward,
} from "./features/rewards/rewardsSlice";
import { purchaseCard } from "./features/shopInventoryManager/shopInventoryManagerSlice";
import { getPriceFromRarity } from "./helpers/getPriceFromRarity";
import { getRarityFromCardType } from "./helpers/getRarityFromCardType";

const confetti = new Confetti();

const FALSE = false;

export function App() {
  const ownedCardTypes = useAppSelector((state) => state.player.ownedCardTypes);

  const sortedOwnedCardTypes = _.sortBy(ownedCardTypes, [
    (cardType) => {
      if (cardType.species === "GECKO") return 0;
      if (cardType.species === "WOLF") return 1;
      if (cardType.species === "RED_PANDA") return 2;
    },
    (cardType) => {
      console.log(cardType, getRarityFromCardType(cardType));
      return getPriceFromRarity(getRarityFromCardType(cardType));
    },
  ]);

  const cardTypesForSale = useAppSelector(
    (state) => state.shop.cardTypesForSale
  );

  const hasSelectedStartingCard = useAppSelector(
    (state) => state.player.hasSelectedStartingCard
  );

  const numGoldCoins = useAppSelector((state) => state.player.numGoldCoins);

  const shopCardRefreshes = useAppSelector(
    (state) => state.refreshes.shopCardRefreshes
  );

  const cardTypesThatHaveBeenPurchased = useAppSelector(
    (state) => state.shopInventoryManager.cardTypesThatHaveBeenPurchased
  );

  const state = useAppSelector((state) => state);

  const dispatch = useAppDispatch();

  const hasClaimedFirstReward = useAppSelector(
    (state) => state.rewards[0].hasBeenClaimed
  );

  const hasClaimedSecondReward = useAppSelector(
    (state) => state.rewards[1]?.hasBeenClaimed
  );

  const hasClaimedThirdReward = useAppSelector(
    (state) => state.rewards[2]?.hasBeenClaimed
  );

  useEffect(() => {
    if (localStorage.getItem("animal-game-state") === null) {
      localStorage.setItem("animal-game-state", JSON.stringify(state));
    }
  }, [state]);

  const [hoveringOver, setHoveringOver] = useState<CardTypeSpecies>();

  const cardTypes = useAppSelector((state) => state.cardTypes);

  const [showMissionInfoModal, setShowMissionInfoModal] = useState(true);

  const hasStartedFirstMission = useAppSelector(
    (state) => state.missions[0]?.startTimestamp
  );

  const itemStacks = useAppSelector((state) => state.itemStacks);

  return (
    <Box>
      <Box sx={{ position: "absolute", zIndex: 0, opacity: 0 }}>
        {cardTypes.map((cardType) => (
          <img
            src={`${cardType.species.toLowerCase()}__${cardType.variant.toLowerCase()}.png`}
          />
        ))}
      </Box>
      <Box sx={{ position: "relative", zIndex: 1 }}>
        <Modal isOpen={!hasClaimedFirstReward} onClose={() => {}} size="lg">
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              {" "}
              <ChakraCard
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                sx={{
                  backgroundImage:
                    'linear-gradient(rgba(255,255,255,0.3), rgba(255,255,255,0.9)), url("/treasure_claim_1.png")',
                  backgroundSize: "cover",
                  width: "min(600px, 100%)",
                  height: "min(600px, 100vmin)",
                  margin: "24px auto 0",
                }}
              >
                <Stack>
                  <CardBody sx={{ fontFamily: "PT Serif" }}>
                    <Box
                      sx={{
                        backgroundColor: "#fffd",
                        padding: "8px",
                        borderRadius: "8px",
                        textAlign: "center",
                        boxShadow:
                          "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                      }}
                    >
                      <Heading size="lg" sx={{ fontFamily: "PT Serif" }}>
                        Reward #1
                      </Heading>

                      <Text py="1" fontSize="24px">
                        You can claim this reward of <strong>5000 </strong>
                        tokens and <strong>10</strong> shop card refreshes :)
                      </Text>
                    </Box>
                  </CardBody>

                  <CardFooter justifyContent="center">
                    <Flex>
                      <Button
                        colorScheme="green"
                        height="60px"
                        width="300px"
                        fontSize="24px"
                        fontFamily="PT Serif"
                        border="1px solid #acb0b5"
                        onClick={() => {
                          confetti.addConfetti({
                            emojis: ["💎"],
                            emojiSize: 80,
                            confettiNumber: 50,
                          });
                          dispatch(claimReward({ rewardId: 1 }));
                        }}
                        size="lg"
                      >
                        Claim Reward!
                      </Button>
                    </Flex>
                  </CardFooter>
                </Stack>
              </ChakraCard>
            </ModalBody>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={hasClaimedFirstReward && !hasClaimedSecondReward}
          onClose={() => {}}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              <ChakraCard
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                sx={{
                  width: "min(600px, 100%)",
                  margin: "24px auto 0",
                }}
              >
                <Stack>
                  <CardBody sx={{ fontFamily: "PT Serif" }}>
                    <Box
                      sx={{
                        backgroundColor: "#fffd",
                        padding: "8px",
                        borderRadius: "8px",
                        textAlign: "center",
                      }}
                    >
                      <Heading size="lg" sx={{ fontFamily: "PT Serif" }}>
                        Reward #2
                      </Heading>

                      <Text py="1" fontSize="24px">
                        To celebrate Octopus joining the shop, you can claim
                        this reward of <strong>5000 </strong>tokens and{" "}
                        <strong>10</strong> shop card refreshes :)
                      </Text>
                    </Box>
                    <Wrap mt="32px" justify="center">
                      {cardTypes
                        .filter((cardType) => cardType.species === "OCTOPUS")
                        .map((cardType) => (
                          <img
                            src={`${cardType.species.toLowerCase()}__${cardType.variant.toLowerCase()}.png`}
                            style={{
                              width: "160px",
                              borderRadius: "80px",
                              boxShadow:
                                "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                            }}
                          />
                        ))}
                    </Wrap>
                  </CardBody>

                  <CardFooter justifyContent="center">
                    <Flex>
                      <Button
                        colorScheme="green"
                        height="60px"
                        width="300px"
                        fontSize="24px"
                        fontFamily="PT Serif"
                        border="1px solid #acb0b5"
                        onClick={() => {
                          confetti.addConfetti({
                            emojis: ["💎"],
                            emojiSize: 80,
                            confettiNumber: 50,
                          });
                          dispatch(createAndClaimReward({ rewardId: 2 }));
                        }}
                        size="lg"
                      >
                        Claim Reward!
                      </Button>
                    </Flex>
                  </CardFooter>
                </Stack>
              </ChakraCard>
            </ModalBody>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={
            hasClaimedFirstReward &&
            hasClaimedSecondReward &&
            !hasClaimedThirdReward
          }
          onClose={() => {}}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              <ChakraCard
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                sx={{
                  width: "min(600px, 100%)",
                  margin: "24px auto 0",
                }}
              >
                <Stack>
                  <CardBody sx={{ fontFamily: "PT Serif" }}>
                    <Box
                      sx={{
                        backgroundColor: "#fffd",
                        padding: "8px",
                        borderRadius: "8px",
                        textAlign: "center",
                      }}
                    >
                      <Heading size="lg" sx={{ fontFamily: "PT Serif" }}>
                        Reward #3
                      </Heading>

                      <Text py="1" fontSize="24px">
                        You can claim this reward of <strong>5000 </strong>
                        tokens and <strong>10</strong> shop card refreshes :)
                      </Text>
                    </Box>
                    <hr></hr>
                    <Box>
                      <Text py="1" fontSize="24px">
                        Missions coming soon! Beginning with{" "}
                        <strong>Moon Mission</strong>. Get ready to put your
                        cards to work and start earning rewards. Make sure you
                        have at least one astronaut in your collection!
                      </Text>

                      <Wrap mt="32px" justify="center">
                        <img
                          src={`moon_mission.png`}
                          style={{
                            width: "240px",
                            borderRadius: "120px",
                            boxShadow:
                              "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                          }}
                        />
                      </Wrap>
                    </Box>
                  </CardBody>

                  <CardFooter justifyContent="center">
                    <Flex>
                      <Button
                        colorScheme="green"
                        height="60px"
                        width="300px"
                        fontSize="24px"
                        fontFamily="PT Serif"
                        border="1px solid #acb0b5"
                        disabled={hasClaimedThirdReward}
                        onClick={() => {
                          confetti.addConfetti({
                            emojis: ["💎"],
                            emojiSize: 80,
                            confettiNumber: 50,
                          });
                          dispatch(createAndClaimReward({ rewardId: 3 }));
                        }}
                        size="lg"
                      >
                        Claim Reward!
                      </Button>
                    </Flex>
                  </CardFooter>
                </Stack>
              </ChakraCard>
            </ModalBody>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={
            hasClaimedFirstReward &&
            hasClaimedSecondReward &&
            hasClaimedThirdReward &&
            showMissionInfoModal &&
            !hasStartedFirstMission
          }
          onClose={() => {}}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              <ChakraCard
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                sx={{
                  width: "min(600px, 100%)",
                  margin: "24px auto 0",
                }}
              >
                <Stack>
                  <CardBody sx={{ fontFamily: "PT Serif" }}>
                    <Box
                      sx={{
                        backgroundColor: "#fffd",
                        padding: "8px",
                        borderRadius: "8px",
                        textAlign: "center",
                      }}
                    >
                      <Heading size="lg" sx={{ fontFamily: "PT Serif" }}>
                        Introducing Moon Mission!
                      </Heading>

                      <Text py="1" fontSize="24px">
                        No easy reward this time. But you can start earning
                        tokens (and more!) through Missions.
                      </Text>
                    </Box>

                    <Wrap mt="32px" justify="center">
                      <img
                        src={`moon_mission.png`}
                        style={{
                          width: "240px",
                          borderRadius: "120px",
                          boxShadow:
                            "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                        }}
                      />
                    </Wrap>
                  </CardBody>

                  <CardFooter justifyContent="center">
                    <Flex>
                      <Button
                        height="60px"
                        width="300px"
                        fontSize="24px"
                        fontFamily="PT Serif"
                        border="1px solid #acb0b5"
                        onClick={() => setShowMissionInfoModal(false)}
                        size="lg"
                      >
                        Close!
                      </Button>
                    </Flex>
                  </CardFooter>
                </Stack>
              </ChakraCard>
            </ModalBody>
          </ModalContent>
        </Modal>

        <Tabs variant="soft-rounded" colorScheme="blue" width="100vw">
          <TabList
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt="24px"
          >
            <Tab fontSize="24px">Animals</Tab>
            <Tab fontSize="24px">Items</Tab>
            <Tab fontSize="24px" isDisabled={!hasSelectedStartingCard}>
              Shop
            </Tab>
            <Tab fontSize="24px" isDisabled={!hasSelectedStartingCard}>
              Missions
            </Tab>
          </TabList>
          <Box textAlign="center" mt="24px">
            <span style={{ fontSize: "24px" }}>
              <span
                style={{
                  borderRadius: "4px",
                  backgroundColor: "#eee",
                  border: "1px solid lightGray",
                  boxShadow:
                    "rgba(0, 0, 0, 0.15) 0px 1.5px 2.5px, rgba(0, 0, 0, 0.05) 0px 0.5px 1px",
                  fontSize: "24px",
                  fontFamily: "PT Serif",
                  padding: "0 16px",
                }}
              >
                {numGoldCoins}
              </span>
              <span
                style={{
                  color: "gray.600",
                  marginLeft: "8px",
                  marginRight: "24px",
                }}
              >
                tokens
              </span>
            </span>
            <span style={{ fontSize: "24px", whiteSpace: "nowrap" }}>
              <span
                style={{
                  borderRadius: "4px",
                  backgroundColor: "#eee",
                  border: "1px solid lightGray",
                  boxShadow:
                    "rgba(0, 0, 0, 0.15) 0px 1.5px 2.5px, rgba(0, 0, 0, 0.05) 0px 0.5px 1px",
                  fontSize: "24px",
                  fontFamily: "PT Serif",
                  padding: "0 16px",
                }}
              >
                {shopCardRefreshes}
              </span>
              <span style={{ color: "gray.600", marginLeft: "8px" }}>
                shop card refreshes
              </span>
            </span>
          </Box>
          <TabPanels>
            <TabPanel>
              <Wrap justify="center">
                {hasSelectedStartingCard ? (
                  sortedOwnedCardTypes.map((cardType, i) => (
                    <Box
                      m="16px"
                      key={`${i}__${cardType.species}__${cardType.variant}`}
                    >
                      <Card
                        species={cardType.species}
                        variant={cardType.variant}
                        shouldShowPrice={false}
                      />
                    </Box>
                  ))
                ) : (
                  <div>
                    <Center
                      mt="48px"
                      mb="-24px"
                      fontSize="18px"
                      fontWeight="500"
                      color="gray.500"
                      textAlign="center"
                    >
                      Select your starting card. Don't worry: you'll be able to
                      buy the others eventually in the shop.
                    </Center>
                    <Wrap justify="center">
                      <Flex
                        flexDir="column"
                        alignItems="center"
                        m="16px"
                        sx={{
                          opacity:
                            hoveringOver && hoveringOver !== "GECKO" ? 0.5 : 1,
                          filter:
                            hoveringOver && hoveringOver !== "GECKO"
                              ? "grayscale(1)"
                              : "",
                          transition: "opacity 0.3s ease, filter 0.3s ease",
                        }}
                      >
                        <Card
                          species={"GECKO"}
                          variant={"BASE"}
                          shouldShowPrice={false}
                        />
                        <Button
                          size="lg"
                          mt="-36px"
                          onClick={() => dispatch(selectStartingCard("GECKO"))}
                          onMouseEnter={() => setHoveringOver("GECKO")}
                          onMouseLeave={() => setHoveringOver(undefined)}
                        >
                          Select
                        </Button>
                      </Flex>
                      <Flex
                        flexDir="column"
                        alignItems="center"
                        m="16px"
                        sx={{
                          opacity:
                            hoveringOver && hoveringOver !== "WOLF" ? 0.5 : 1,
                          filter:
                            hoveringOver && hoveringOver !== "WOLF"
                              ? "grayscale(1)"
                              : "",
                          transition: "opacity 0.3s ease, filter 0.3s ease",
                        }}
                      >
                        <Card
                          species={"WOLF"}
                          variant={"BASE"}
                          shouldShowPrice={false}
                        />
                        <Button
                          size="lg"
                          width="80px"
                          mt="-36px"
                          onClick={() => dispatch(selectStartingCard("WOLF"))}
                          onMouseEnter={() => setHoveringOver("WOLF")}
                          onMouseLeave={() => setHoveringOver(undefined)}
                        >
                          Select
                        </Button>
                      </Flex>
                      <Flex
                        flexDir="column"
                        alignItems="center"
                        m="16px"
                        sx={{
                          opacity:
                            hoveringOver && hoveringOver !== "RED_PANDA"
                              ? 0.5
                              : 1,
                          filter:
                            hoveringOver && hoveringOver !== "RED_PANDA"
                              ? "grayscale(1)"
                              : "",
                          transition: "opacity 0.3s ease, filter 0.3s ease",
                        }}
                      >
                        <Card
                          species={"RED_PANDA"}
                          variant={"BASE"}
                          shouldShowPrice={false}
                        />
                        <Button
                          size="lg"
                          width="80px"
                          mt="-36px"
                          onClick={() =>
                            dispatch(selectStartingCard("RED_PANDA"))
                          }
                          onMouseEnter={() => setHoveringOver("RED_PANDA")}
                          onMouseLeave={() => setHoveringOver(undefined)}
                        >
                          Select
                        </Button>
                      </Flex>
                    </Wrap>
                  </div>
                )}
              </Wrap>
            </TabPanel>
            <TabPanel>
              <Wrap justify="center" mt="32px">
                {itemStacks.map((item) => (
                  <ItemStack itemStack={item} />
                ))}
              </Wrap>
            </TabPanel>
            <TabPanel>
              <Wrap justify="center">
                {cardTypesForSale.map((cardType, i) => (
                  <Box
                    sx={{
                      position: "relative",
                    }}
                    key={`${i}__${cardType.species}__${cardType.variant}`}
                  >
                    <div
                      style={{
                        borderRadius: "4px",
                        position: "absolute",
                        top: "36px",
                        left: "0px",
                        right: "0px",
                        fontSize: "24px",
                        padding: "0 16px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 2,
                      }}
                    >
                      <Button
                        colorScheme={
                          cardTypesThatHaveBeenPurchased.includes(i)
                            ? "green"
                            : "red"
                        }
                        size="sm"
                        onClick={() => {
                          if (cardTypesThatHaveBeenPurchased.includes(i)) {
                            dispatch(refreshShopCard({ i, ownedCardTypes }));
                            return;
                          }

                          const hasConfirmed = window.confirm(
                            "Are you SURE you want to refresh this shop card?"
                          );
                          if (hasConfirmed)
                            dispatch(refreshShopCard({ i, ownedCardTypes }));
                        }}
                        isDisabled={shopCardRefreshes <= 0}
                      >
                        refresh
                      </Button>
                    </div>
                    <Flex
                      m="16px"
                      flexDir="column"
                      alignItems="center"
                      sx={{
                        opacity: cardTypesThatHaveBeenPurchased.includes(i)
                          ? 0.5
                          : 1,
                        filter: cardTypesThatHaveBeenPurchased.includes(i)
                          ? "grayscale(1)"
                          : "",
                        transition: "opacity 0.3s ease, filter 0.3s ease",
                        position: "relative",
                      }}
                    >
                      <Card
                        species={cardType.species}
                        variant={cardType.variant}
                        shouldShowPrice={true}
                      />
                      <Button
                        size="lg"
                        width="80px"
                        mt="-36px"
                        disabled={
                          numGoldCoins <
                          getPriceFromRarity(
                            getRarityFromCardType(cardType) ||
                              cardTypesThatHaveBeenPurchased.includes(i)
                          )
                        }
                        onClick={() => {
                          dispatch(
                            purchaseCard({
                              cardType,
                              i,
                              price: getPriceFromRarity(
                                getRarityFromCardType(cardType) ||
                                  cardTypesThatHaveBeenPurchased.includes(i)
                              ),
                            })
                          );
                          confetti.addConfetti({
                            emojis: ["🎉"],
                            emojiSize: 80,
                            confettiNumber: 50,
                          });
                        }}
                      >
                        {cardTypesThatHaveBeenPurchased.includes(i)
                          ? "Bought!"
                          : "Buy"}
                      </Button>
                    </Flex>
                  </Box>
                ))}
              </Wrap>
            </TabPanel>
            <TabPanel>
              <Missions />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
}
