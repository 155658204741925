import { Center } from "@chakra-ui/react";
import {
  getImgUrlFromItemType,
  getItemNameFromItemType,
  ItemStack as ItemStackType,
} from "./itemStacksSlice";

export function ItemStack({ itemStack }: { itemStack: ItemStackType }) {
  return (
    <Center
      sx={{
        width: "calc(256px + 32px)",
        fontFamily: "PT Serif",
        fontSize: "24px",
        position: "relative",
      }}
      flexDir="column"
    >
      <span
        style={{
          borderRadius: "4px",
          backgroundColor: "#eee",
          border: "1px solid lightGray",
          boxShadow:
            "rgba(0, 0, 0, 0.15) 0px 1.5px 2.5px, rgba(0, 0, 0, 0.05) 0px 0.5px 1px",
          fontSize: "24px",
          fontFamily: "PT Serif",
          padding: "0 16px",
          whiteSpace: "nowrap",
          position: "relative",
          top: "8px",
        }}
      >
        {getItemNameFromItemType(itemStack.itemType)}
      </span>

      <img
        src={getImgUrlFromItemType(itemStack.itemType)}
        style={{
          width: "256px",
          borderRadius: "128px",
          boxShadow: "rgba(0, 0, 0, 0.5) 0px 1px 3px",
        }}
      />
      <span
        style={{
          borderRadius: "4px",
          backgroundColor: "#eee",
          border: "1px solid lightGray",
          boxShadow:
            "rgba(0, 0, 0, 0.15) 0px 1.5px 2.5px, rgba(0, 0, 0, 0.05) 0px 0.5px 1px",
          fontSize: "24px",
          fontFamily: "PT Serif",
          padding: "0 16px",
          position: "relative",
          top: "-8px",
        }}
      >
        {itemStack.quantity}
      </span>
    </Center>
  );
}
