import { Box, Flex } from "@chakra-ui/react";
import { useAppDispatch } from "../../app/hooks";
import { isCardTypeEligibleForMission } from "../../helpers/isCardTypeEligibleForMission";

import { Card } from "../cardTypes/Card";
import { CardType } from "../cardTypes/cardTypesSlice";
import { assignCardToMission, MissionToken } from "./missionsSlice";

export function MissionCard({
  mission,
  cardType,
}: {
  mission: MissionToken;
  cardType: CardType;
}) {
  const assignedCards = mission.assignedCards;

  const dispatch = useAppDispatch();

  return (
    <Flex
      flexDir="column"
      alignItems={"center"}
      key={`${cardType.species}__${cardType.variant}`}
      sx={{
        padding: "16px",
        cursor: isCardTypeEligibleForMission({
          cardType,
          missionType: mission.missionType,
        })
          ? "pointer"
          : "curosr",
        opacity: isCardTypeEligibleForMission({
          cardType,
          missionType: mission.missionType,
        })
          ? 1
          : 0.5,
        userSelect: "none",
      }}
      onClick={() =>
        isCardTypeEligibleForMission({
          cardType,
          missionType: mission.missionType,
        }) &&
        dispatch(
          assignCardToMission({ missionType: mission.missionType, cardType })
        )
      }
    >
      <Box
        sx={{
          boxShadow: assignedCards.includes(cardType)
            ? "0 0 20px 10px yellow"
            : "none",
        }}
      >
        <Card
          species={cardType.species}
          variant={cardType.variant}
          shouldShowPrice={false}
          size="sm"
        />
      </Box>
    </Flex>
  );
}
