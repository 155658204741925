export function getMissionRequirementDescriptionFromType(type: string) {
  switch (type) {
    case "BASE":
      return "Assign any card to this mission";
    case "MOON_MISSION":
      return "Assign any astronaut to this mission";
    default:
      throw Error("Invalid mission type: " + type);
  }
}
