import { createAction, createSlice } from "@reduxjs/toolkit";
import { CardType } from "../cardTypes/cardTypesSlice";
import { claimReward, createAndClaimReward } from "../rewards/rewardsSlice";

export const refreshShopCard = createAction<{
  i: number;
  ownedCardTypes: CardType[];
}>("refreshShopCard");

export interface Refreshes {
  shopCardRefreshes: number;
  missionRefreshes: number;
}

let savedState;
if (localStorage.getItem("animal-game-state") !== null) {
  savedState = JSON.parse(localStorage.getItem("animal-game-state") as string)
    .refreshes as Refreshes;
}

const initialState = savedState
  ? savedState
  : {
      shopCardRefreshes: 5,
      missionRefreshes: 5,
    };

export const refreshesSlice = createSlice({
  name: "refreshesSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(refreshShopCard, (state, action) => {
        state.shopCardRefreshes--;
      })
      .addCase(claimReward, (state, action) => {
        if (action.payload.rewardId === 1) state.shopCardRefreshes += 10;
      })
      .addCase(createAndClaimReward, (state, action) => {
        if (action.payload.rewardId === 2) state.shopCardRefreshes += 10;
        if (action.payload.rewardId === 3) state.shopCardRefreshes += 10;
      });
  },
});

export const refreshesReducer = refreshesSlice.reducer;
