import { createSlice } from "@reduxjs/toolkit";
import { claimRewards } from "../missions/missionsSlice";

export function getImgUrlFromItemType(itemType: ItemType) {
  switch (itemType) {
    case "MOON_ROCK":
      return "/moon_rock_melting.png";
    default:
      throw new Error("Unknown item type");
  }
}

export function getItemNameFromItemType(itemType: ItemType) {
  switch (itemType) {
    case "MOON_ROCK":
      return "Moon Rock";
    default:
      throw new Error("Unknown item type");
  }
}

export type ItemType = "MOON_ROCK";

export type ItemStack = {
  itemType: ItemType;
  quantity: number;
};

let savedState;
if (localStorage.getItem("animal-game-state") !== null) {
  const savedTotalState = JSON.parse(
    localStorage.getItem("animal-game-state") as string
  );

  const hasClaimedRewardForMoonMission = savedTotalState.missions.find(
    (mission: any) =>
      mission.missionType === "MOON_MISSION" && mission.hasRewardBeenClaimed
  );

  const haveNoItems = savedTotalState.itemStacks.length === 0;

  console.log("hasClaimedRewardForMoonMission", hasClaimedRewardForMoonMission);

  console.log("haveNoItems", haveNoItems);

  if (hasClaimedRewardForMoonMission && haveNoItems) {
    savedState = [{ itemType: "MOON_ROCK" as "MOON_ROCK", quantity: 3 }];
    localStorage.setItem(
      "animal-game-state",
      JSON.stringify({ ...savedTotalState, itemStacks: savedState })
    );
  } else {
    savedState = JSON.parse(localStorage.getItem("animal-game-state") as string)
      .itemStacks as ItemStack[];
  }
}

export const initialState: ItemStack[] = savedState ? savedState : [];

export const itemStacksSlice = createSlice({
  name: "itemStacks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(claimRewards, (state, action) => {
      if (action.payload.missionType === "MOON_MISSION") {
        state.push({ itemType: "MOON_ROCK", quantity: 3 });
      }
    });
  },
});

export const itemStacksReducer = itemStacksSlice.reducer;
