import { useState } from "react";
import { capitalizeOnlyFirstLetter } from "../../helpers/capitalizeOnlyFirstLetter";
import { getPriceFromRarity } from "../../helpers/getPriceFromRarity";
import { getRarityFromCardType } from "../../helpers/getRarityFromCardType";
import { getStripeColorFromCardTypeSpecies } from "../../helpers/getStripeColorFromCardTypes";
import { CardTypeSpecies, CardTypeVariant } from "./cardTypesSlice";

export function Card({
  species,
  variant,
  shouldShowPrice,
  size = "lg",
}: {
  species: CardTypeSpecies;
  variant: CardTypeVariant;
  shouldShowPrice: boolean;
  size?: string;
}) {
  const [hasImageLoaded, setHasImageLoaded] = useState(false);

  return (
    <div
      className={`box`}
      style={{
        position: "relative",
        padding: size === "sm" ? "8px 8px 18px 8px" : "16px 16px 36px 16px",
        width: size === "sm" ? "116px" : "auto",
        margin: size === "sm" ? "none" : "64px min(64px, 5vw)",
        boxShadow:
          size === "sm"
            ? "rgba(0, 0, 0, 0.5) 0px 1px 3px"
            : "rgba(0, 0, 0, 0.5) 0px 5px 15px",
        background: `
      repeating-linear-gradient(
        45deg,
        transparent,
        transparent 10px,
        #fff 10px,
        #fdfeff 20px
      ),
      linear-gradient(to bottom, ${getStripeColorFromCardTypeSpecies(
        species
      )}, ${getStripeColorFromCardTypeSpecies(species)})`,
      }}
    >
      {shouldShowPrice && (
        <div
          style={{
            borderRadius: "4px",
            position: "absolute",
            top: "4px",
            right: "-20px",
            backgroundColor: "#eee",
            border: "1px solid lightGray",
            transform: "rotate(30deg)",
            boxShadow:
              "rgba(0, 0, 0, 0.15) 0px 1.5px 2.5px, rgba(0, 0, 0, 0.05) 0px 0.5px 1px",
            fontSize: "24px",
            padding: "0 16px",
          }}
        >
          {getPriceFromRarity(getRarityFromCardType({ species, variant }))}
        </div>
      )}

      <img
        src={`${species.toLowerCase()}__${variant.toLowerCase()}.png`}
        style={{
          height: size === "sm" ? "100px" : "300px",
          width: size === "sm" ? "100px" : "300px",
        }}
        onLoad={() => setHasImageLoaded(true)}
      />

      <div
        style={{
          color: "#345",
          fontSize: size === "sm" ? "14px" : "24px",
          background: "#fffc",
          marginRight: size === "sm" ? "-8px" : "-16px",
          marginLeft: size === "sm" ? "-8px" : "-16px",
          marginTop: "24px",
          border: "1px solid transparent",
          borderTop: "1px solid #999",
          borderBottom: "1px solid #999",
          padding: "2px 0 4px",
        }}
      >
        <div style={{ fontWeight: 600, textAlign: "center" }}>
          {species
            .split("_")
            .map((word) => capitalizeOnlyFirstLetter(word))
            .join(" ")}
        </div>
        <div
          style={{
            fontSize: "smaller",
            textAlign: "center",
            marginTop: "-4px",
          }}
        >
          {variant
            .split("_")
            .map((word) => capitalizeOnlyFirstLetter(word))
            .join(" ")}
        </div>
      </div>

      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "12px",
        }}
      >
        <div
          style={{
            color: "#345",
            fontSize: "36px",
            background: "#fffc",
            marginRight: "-16px",
            marginLeft: "-16px",
            marginTop: "16px",
            border: "1px solid #999",
            borderRadius: "24px",
            padding: "0px 16px",
          }}
        >
          <Box
            sx={{
              color: "yellow.400",
              position: "relative",
              top: "-3px",
              filter: "saturate(5)",
            }}
          >
            ⭑⭑⭑⭑⭑
          </Box>
        </div>
      </div> */}
    </div>
  );
}
