import { CardTypeRarity } from "../features/cardTypes/cardTypesSlice";

export function getPriceFromRarity(rarity: CardTypeRarity): number {
  switch (rarity) {
    case "COMMON":
      return 500;
    case "UNCOMMON":
      return 1000;
    case "RARE":
      return 2000;
    case "EPIC":
      return 4000;
    case "LEGENDARY":
      return 8000;
    default:
      throw Error("Invalid card type rarity");
  }
}
