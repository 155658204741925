import {
  Box,
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  Wrap,
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getMissionRequirementDescriptionFromType } from "../../helpers/getMissionRequirementDescriptionFromType";
import { getMissionTitleFromType } from "../../helpers/getMissionTitleFromType";
import { getPriceFromRarity } from "../../helpers/getPriceFromRarity";
import { getRarityFromCardType } from "../../helpers/getRarityFromCardType";

import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { getMissionFlavorTextFromType } from "../../helpers/getMissionFlavorTestFromType";
import { CardHead } from "../cardTypes/CardHead";
import { MissionCard } from "./MissionCard";
import {
  claimRewards,
  getMissionDurationInSecondsByType,
  MissionToken,
  startMission,
} from "./missionsSlice";
export function Mission({ mission }: { mission: MissionToken }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isRewardsOpen,
    onOpen: onRewardsOpen,
    onClose: onRewardsClose,
  } = useDisclosure();

  const ownedCardTypes = useAppSelector((state) => state.player.ownedCardTypes);
  const assignedCards = mission.assignedCards;

  const dispatch = useAppDispatch();

  const sortedOwnedCardTypes = _.sortBy(ownedCardTypes, [
    (cardType) => {
      if (cardType.species === "GECKO") return 0;
      if (cardType.species === "WOLF") return 1;
      if (cardType.species === "RED_PANDA") return 2;
    },
    (cardType) => {
      console.log(cardType, getRarityFromCardType(cardType));
      return getPriceFromRarity(getRarityFromCardType(cardType));
    },
  ]);

  const hasMissionStarted = !!mission.startTimestamp;

  const [timeRemainingInMilliseconds, setTimeRemainingInMilliseconds] =
    useState(
      getMissionDurationInSecondsByType(mission.missionType) * 1000 -
        (Date.now() - (mission.startTimestamp as number))
    );

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemainingInMilliseconds(
        getMissionDurationInSecondsByType(mission.missionType) * 1000 -
          (Date.now() - (mission.startTimestamp as number))
      );

      return () => clearInterval(interval);
    }, 1000 * 60); // updates time every minute

    return () => clearInterval(interval);
  }, []);

  const isTimeUp = timeRemainingInMilliseconds < 0;

  return (
    <div
      style={{
        border: "1px solid lightGray",
        boxShadow:
          "rgba(0, 0, 0, 0.15) 0px 1.5px 2.5px, rgba(0, 0, 0, 0.05) 0px 0.5px 1px",
        backgroundImage:
          'linear-gradient(rgba(255,255,255,0.0), rgba(255,255,255,0.2)), url("/moon_mission.png")',
        backgroundSize: "cover",

        fontSize: "24px",
        fontFamily: "PT Serif",
        padding: "16px",
        width: "min(100vw, 600px)",
      }}
    >
      {hasMissionStarted && !isTimeUp && (
        <Text
          sx={{ background: "#ffff", padding: "8px", borderRadius: "2px" }}
          mb="8px"
        >
          <strong style={{ fontSize: "24px", fontWeight: 700 }}>
            Your crew has embarked on this mission!
          </strong>
          <Wrap my="8px">
            {assignedCards.map((ac) => (
              <Box ml="16px" pb="8px">
                <CardHead species={ac.species} variant={ac.variant} />
              </Box>
            ))}
          </Wrap>
        </Text>
      )}
      {hasMissionStarted &&
        (!isTimeUp ? (
          <Text
            sx={{ background: "#ffff", padding: "8px", borderRadius: "2px" }}
            mb="8px"
          >
            <strong style={{ fontSize: "24px", fontWeight: 700 }}>
              {moment.duration(timeRemainingInMilliseconds).humanize()}
            </strong>{" "}
            remaining ⌛⌛⌛⌛⌛
          </Text>
        ) : null)}

      {hasMissionStarted && (
        <>
          <Text
            sx={{
              background: "#ffff",
              padding: "8px",
              borderRadius: isTimeUp ? "2px 2px 0 0" : "2px",
            }}
            mb={isTimeUp ? 0 : "8px"}
          >
            {!isTimeUp ? (
              <span>
                You've already received{" "}
                <span style={{ fontSize: "24px" }}>
                  <span
                    style={{
                      borderRadius: "4px",
                      backgroundColor: "#eee",
                      border: "1px solid lightGray",
                      boxShadow:
                        "rgba(0, 0, 0, 0.15) 0px 1.5px 2.5px, rgba(0, 0, 0, 0.05) 0px 0.5px 1px",
                      fontSize: "24px",
                      fontFamily: "PT Serif",
                      padding: "0 16px",
                    }}
                  >
                    3000
                  </span>{" "}
                  <span
                    style={{
                      color: "gray.600",
                    }}
                  >
                    tokens
                  </span>
                </span>{" "}
                for accepting the mission. After completing the mission, you'll
                receive another{" "}
                <span
                  style={{
                    borderRadius: "4px",
                    backgroundColor: "#eee",
                    border: "1px solid lightGray",
                    boxShadow:
                      "rgba(0, 0, 0, 0.15) 0px 1.5px 2.5px, rgba(0, 0, 0, 0.05) 0px 0.5px 1px",
                    fontSize: "24px",
                    fontFamily: "PT Serif",
                    padding: "0 16px",
                  }}
                >
                  3000
                </span>{" "}
                <span
                  style={{
                    color: "gray.600",
                  }}
                >
                  tokens
                </span>{" "}
                and ?????{" "}
              </span>
            ) : (
              <span>
                Your crew has completed the mission, and survived! You can now
                claim your additional{" "}
                <span
                  style={{
                    borderRadius: "4px",
                    backgroundColor: "#eee",
                    border: "1px solid lightGray",
                    boxShadow:
                      "rgba(0, 0, 0, 0.15) 0px 1.5px 2.5px, rgba(0, 0, 0, 0.05) 0px 0.5px 1px",
                    fontSize: "24px",
                    fontFamily: "PT Serif",
                    padding: "0 16px",
                  }}
                >
                  3000
                </span>{" "}
                <span
                  style={{
                    color: "gray.600",
                  }}
                >
                  tokens
                </span>
                , and whatever else your crew might have uncovered...
              </span>
            )}
          </Text>
          {isTimeUp && (
            <Center
              sx={{
                background: "#ffff",
                padding: "8px 8px 24px 8px",
                borderRadius: " 0 0 2px 2px",
              }}
            >
              <Button
                colorScheme="blue"
                size="lg"
                onClick={onRewardsOpen}
                fontSize="22px"
                fontWeight="700"
                mt="8px"
              >
                Claim Mission Rewards! 🎉🎉🎉
              </Button>
            </Center>
          )}
        </>
      )}

      <h2
        style={{
          background: "#ffff",
          fontWeight: 600,
          padding: "8px",
          borderRadius: "2px",
          marginTop: "280px",
          marginBottom: "8px",
        }}
      >
        {getMissionTitleFromType(mission.missionType)}
      </h2>

      <Text sx={{ background: "#ffff", padding: "8px", borderRadius: "2px" }}>
        {getMissionFlavorTextFromType(mission.missionType)}
      </Text>
      {!hasMissionStarted && (
        <Text
          sx={{ background: "#ffff", padding: "8px", borderRadius: "2px" }}
          mt="8px"
        >
          This mission takes{" "}
          <strong>
            {moment
              .duration(
                getMissionDurationInSecondsByType(mission.missionType) * 1000
              )
              .humanize()}
          </strong>{" "}
          to complete.
        </Text>
      )}
      {!hasMissionStarted && (
        <Center m="16px">
          <Button
            colorScheme="blue"
            size="lg"
            onClick={onOpen}
            fontSize="22px"
            fontWeight="700"
            mt="8px"
          >
            Assign your crew!
          </Button>
        </Center>
      )}
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent sx={{ fontFamily: "PT Serif" }}>
          <ModalHeader>
            {getMissionTitleFromType(mission.missionType)}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody fontSize="18px">
            {getMissionRequirementDescriptionFromType(mission.missionType)}{" "}
            (click to assign)
            <Wrap mt="8px">
              {sortedOwnedCardTypes.map((cardType, i) => (
                <MissionCard cardType={cardType} mission={mission} />
              ))}
            </Wrap>
            <Center fontSize="24px">
              <span style={{ whiteSpace: "nowrap" }}>
                {assignedCards.length} selected
              </span>{" "}
              {assignedCards.map((ac) => (
                <span style={{ margin: "0 8px" }}>
                  <CardHead species={ac.species} variant={ac.variant} />
                </span>
              ))}
            </Center>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                dispatch(startMission({ missionType: mission.missionType }));
                onClose();
              }}
              isDisabled={
                !assignedCards.find((ac) => ac.variant === "ASTRONAUT")
              }
            >
              Start Mission!
            </Button>
            <Button colorScheme="blue" variant="outline" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isRewardsOpen} onClose={onRewardsClose} size="lg">
        <ModalOverlay />
        <ModalContent sx={{ fontFamily: "PT Serif" }}>
          <ModalHeader>
            {getMissionTitleFromType(mission.missionType)}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody fontSize="20px">
            Your crew flew to the moon and back! Take a moment to appreciate
            their accomplishment. As a reward, you can now claim your additional
            3000 tokens. You also found some extremely unusual moon rocks... You
            can keep them! For now at least. They are in your "Items" tab.
            <img
              src="/moon_rock_melting.png"
              style={{ width: "100%", borderRadius: "50%", margin: "32px 0" }}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                dispatch(claimRewards({ missionType: mission.missionType }));
                onRewardsClose();
              }}
            >
              Claims Rewards!
            </Button>
            <Button
              colorScheme="blue"
              variant="outline"
              onClick={onRewardsClose}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}
