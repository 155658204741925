import { createSlice } from "@reduxjs/toolkit";

export interface cardTypesSlice {
  value: number;
  status: "idle" | "loading" | "failed";
}

export type CardTypeSpecies = "GECKO" | "WOLF" | "RED_PANDA" | "OCTOPUS";

export type CardTypeVariant =
  | "BASE"
  | "ASTRONAUT"
  | "TUXEDO"
  | "WIZARD"
  | "WARLOCK"
  | "STAINED_GLASS"
  | "OIL_PAINTING"
  | "BLACK_AND_WHITE"
  | "PSYCHEDELIC"
  | "VAN_GOGH"
  | "ULTRA_TURQUOISE";

export type CardTypeRarity =
  | "COMMON"
  | "UNCOMMON"
  | "RARE"
  | "EPIC"
  | "LEGENDARY";

export interface CardType {
  species: CardTypeSpecies;
  variant: CardTypeVariant;
}

export const initialState: CardType[] = [
  { species: "GECKO", variant: "BASE" },
  { species: "GECKO", variant: "TUXEDO" },
  { species: "GECKO", variant: "ASTRONAUT" },
  { species: "GECKO", variant: "OIL_PAINTING" },
  { species: "GECKO", variant: "BLACK_AND_WHITE" },
  { species: "GECKO", variant: "PSYCHEDELIC" },
  { species: "GECKO", variant: "WIZARD" },
  { species: "GECKO", variant: "STAINED_GLASS" },
  { species: "WOLF", variant: "BASE" },
  { species: "WOLF", variant: "TUXEDO" },
  { species: "WOLF", variant: "ASTRONAUT" },
  { species: "WOLF", variant: "WIZARD" },
  { species: "WOLF", variant: "WARLOCK" },
  { species: "WOLF", variant: "OIL_PAINTING" },
  { species: "WOLF", variant: "VAN_GOGH" },
  { species: "WOLF", variant: "STAINED_GLASS" },
  { species: "RED_PANDA", variant: "BASE" },
  { species: "RED_PANDA", variant: "TUXEDO" },
  { species: "RED_PANDA", variant: "ASTRONAUT" },
  { species: "RED_PANDA", variant: "OIL_PAINTING" },
  { species: "RED_PANDA", variant: "ULTRA_TURQUOISE" },
  { species: "OCTOPUS", variant: "BASE" },
  { species: "OCTOPUS", variant: "ASTRONAUT" },
  { species: "OCTOPUS", variant: "OIL_PAINTING" },
  { species: "OCTOPUS", variant: "BLACK_AND_WHITE" },
  { species: "OCTOPUS", variant: "STAINED_GLASS" },
];

export const cardsSlice = createSlice({
  name: "cards",
  initialState,
  reducers: {},
});

export const cardTypesReducer = cardsSlice.reducer;
