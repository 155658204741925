export function getMissionFlavorTextFromType(type: string) {
  switch (type) {
    case "BASE":
      return "Assign any card to this mission";
    case "MOON_MISSION":
      return "Send one (or more) of your astronauts on a very dangerous mission to the moon. You'll receive 3000 tokens at the start of the mission, and 3000 tokens at the end. If you're lucky (and survive) you might even find valuable moon rocks. I've heard, but can't confirm, that the more astronauts you assign to the mission, the more likely you are to find some...";
    default:
      throw Error("Invalid mission type: " + type);
  }
}
