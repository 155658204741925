import { createAction, createSlice } from "@reduxjs/toolkit";
import { CardType } from "../cardTypes/cardTypesSlice";
import { refreshShopCard } from "../refreshes/refreshesSlice";

export const purchaseCard = createAction<{
  cardType: CardType;
  i: number;
  price: number;
}>("purchaseCard");

export interface ShopInventoryManager {
  cardTypesThatHaveBeenPurchased: number[];
  counter: 1;
}

let savedState;
if (localStorage.getItem("animal-game-state") !== null) {
  savedState = JSON.parse(localStorage.getItem("animal-game-state") as string)
    .shopInventoryManager as ShopInventoryManager;
}

const initialState = savedState
  ? savedState
  : {
      cardTypesThatHaveBeenPurchased: [] as number[],
    };

if (localStorage.getItem("animal-game-state") !== null) {
  const state = JSON.parse(localStorage.getItem("animal-game-state") as string);
  state.shopInventoryManager = initialState;
  localStorage.setItem("animal-game-state", JSON.stringify(state));
}

export const shopInventoryManagerSlice = createSlice({
  name: "shopInventoryManager",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(purchaseCard, (state, action) => {
        state.cardTypesThatHaveBeenPurchased.push(action.payload.i);
      })
      .addCase(refreshShopCard, (state, action) => {
        const i = state.cardTypesThatHaveBeenPurchased.indexOf(
          action.payload.i
        );
        state.cardTypesThatHaveBeenPurchased = [
          ...state.cardTypesThatHaveBeenPurchased.slice(0, i),
          ...state.cardTypesThatHaveBeenPurchased.slice(i + 1),
        ];
      });
  },
});

export const shopInventoryManagerReducer = shopInventoryManagerSlice.reducer;
